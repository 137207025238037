<template>
  <div>
    <b-card no-body class="mb-1">
      <div class="d-flex justify-content-between align-items-center px-1 py-1">
        <div>
          <h5 class="m-0 font-weight-bolder">{{ $t("Mantenimiento") }}</h5>
        </div>
        <div class="d-flex align-items-center">
          <span
            class="ml-1 cursor-pointer"
            @click="visibleFilter = !visibleFilter"
          >
            <feather-icon icon="FilterIcon" size="20" />
          </span>
        </div>
      </div>

      <div>
        <b-collapse id="collapse" v-model="visibleFilter" class="mt-2">
          <div class="px-2">
            <b-row>
              <b-col sm="6">
                <b-form-group
                  :label="$t('FechaInicio')"
                  label-for="filter-fechaini"
                >
                  <flat-pickr
                    v-model="date_ini"
                    name="fecha_ini"
                    class="form-control"
                    :config="{ enableTime: false }"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group
                  :label="$t('FechaFin')"
                  label-for="filter-fechafin"
                >
                  <flat-pickr
                    v-model="date_fin"
                    name="fecha_ini"
                    class="form-control"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="6">
                <b-form-group :label="$t('Tipo')" label-for="filter-type">
                  <v-select
                    v-model="type"
                    label="name"
                    :filterable="false"
                    :searchable="false"
                    :options="events"
                    :placeholder="$t('Evento')"
                  >
                    <template slot="option" slot-scope="option">
                      {{ $t('typeevents.' + option.alias) }}
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      {{ $t('typeevents.' + option.alias) }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <b-col sm="6">
                <b-form-group :label="$t('Usuario')" label-for="filter-order">
                  <SelectUsers v-model="user" />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </b-collapse>
      </div>
      <b-table hover responsive :items="items" :fields="columns">
        <template #cell(created_at)="data">
          {{ toDateWithTime(data.item.created_at).toString().split(" ")[0] }}
        </template>
        <template #cell(created_at_hour)="data">
          {{ toDateWithTime(data.item.created_at).toString().split(" ")[1] }}
        </template>
        <template #cell(type)="data">
          {{ $t(`typeevents.${data.item.type}`) }}
        </template>
        <template #cell(object)="data">
          <b-link
            v-if="data.item.object"
            :to="{ name: viewObject(data.item.object_type), params: { id: data.item.object.id } }"
          >
            <div class="d-flex align-items-center">
              <div
                :style="`background-image: url('${data.item.product.docs.imagen}');`"
                class="d-inline-block img-product-list mr-50"
              ></div>
              <p class="d-inline-block m-0">{{ data.item.object.name }}</p>
            </div>
          </b-link>
        </template>
        <template #cell(status)="data">
          <div class="mb-2 mt-1">
            <b-badge
              v-if="data.item.status"
              :variant="returnStatus(data.item.status.alias, data.item.object_type)"
              class="mr-50"
            >
              {{ $t(`status.${data.item.object_type}s.${data.item.status.alias}`) }}
            </b-badge>
            <b-badge v-if="data.item.apto === 0" variant="danger">
              {{ $t("NO APTO") }}
            </b-badge>
          </div>
        </template>
        <template #cell(user)="{ value }">
          <b-link
            :to="{ name: 'viewUser', params: { id: value.id } }"
            target="_blank"
            class="link"
          >
            <b-avatar v-if="value" size="md" :src="value.avatar_url" />
            <span class="text-nowrap ml-1">
              {{ value.name }}
              {{ value.surname }}
            </span>
          </b-link>
        </template>
        <template #cell(assign_user)="{ value }">
          <b-link
            :to="{ name: 'viewUser', params: { id: value.id } }"
            target="_blank"
            class="link"
          >
            <b-avatar v-if="value" size="md" :src="value.avatar_url" />
            <span class="text-nowrap ml-1">
              {{ value.name }}
              {{ value.surname }}
            </span>
          </b-link>
        </template>
        <template #cell(actions)="data">
          <b-link v-if="data.item.url" :href="data.item.url" target="_blank">
            <feather-icon icon="DownloadIcon" size="16" />
          </b-link>
        </template>
      </b-table>
    </b-card>
    <div class="d-flex align-items-center justify-content-between mb-2">
      <div class="d-flex align-items-center mb-0">
        <span class="text-nowrap"> {{ $t("PorPagina") }}: </span>
        <b-form-select
          v-model="pageLength"
          :options="pages"
          class="ml-50 mr-1"
          @input="handlePageChange"
        />
        <span class="text-nowrap"> {{ $t("Total") }}: {{ totalItems }}</span>
      </div>
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalItems"
          :per-page="pageLength"
          first-number
          last-number
          align="right"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
          @change="handleChangePage"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import {
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BLink,
  BTable,
  BCard,
  BCollapse,
  BButton,
  BAvatar,
} from "bootstrap-vue";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import store from "@/store";
import flatPickr from "vue-flatpickr-component";
import {
  FlatPickrToTimestamp,
  TimestampToFlatPickr,
  TimestampToFlatPickrWithTime,
  returnStatus,
  viewObject,
} from "@/libs/helpers";
import { config } from "@/shared/app.config";
import vSelect from "vue-select";
import SelectUsers from '@/components/selectUsers/SelectUsers.vue'


export default {
  components: {
    BTable,
    BBadge,
    BLink,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCard,
    flatPickr,
    BRow,
    vSelect,
    BCol,
    BCollapse,
    BButton,
    BAvatar,
    SelectUsers,
  },
  data() {
    return {
      log: [],
      visibleFilter: false,
      dir: false,
      currentPage: 1,
      statusAssets: config.statusAssetsVariants,
      statusEpis: config.statusEpisVariants,
      statusInstallations: config.statusInstallationsVariants,
      pages: ["10", "15", "25"],
      pageLength: 10,
      columns: [
        { key: "created_at", label: this.$t("Fecha") },
        { key: "created_at_hour", label: this.$t("Hora") },
        { key: "type", label: this.$t("Evento") },
        { key: "assign_user", label: this.$t("Usuario") },
        { key: 'user', label: this.$t('Responsable') },
        { key: "object", label: this.$t("Activo") },
        { key: "status", label: this.$t("Estado") },
        { key: "actions", label: this.$t("Acciones"), class: "text-right" },
      ],
      searchTerm: "",
      date_ini: "",
      date_fin: "",
      priority: "",
      status: "",
      selectPriorities: config.priorities,
      user: "",
      type: "",
    };
  },
  computed: {
    ...mapGetters({
      currentCompany: "companies/getCurrentCompany",
      currentClient: "clients/getCurrentClient",
      items: "events/getItemsLogs",
      totalItems: "events/getTotalItemsLogs",
      selectStatus: "assets/getSelectStatus",
      events: "events/getItems",
    }),
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  watch: {
    status() {
      this.currentPage = 1
      this.chargeData()
    },
    type() {
      this.currentPage = 1
      this.chargeData()
    },
    date_ini() {
      this.currentPage = 1
      this.chargeData()
    },
    date_fin() {
      this.currentPage = 1
      this.chargeData()
    },
    user() {
      this.currentPage = 1
      this.chargeData()
    },
  },
  async created() {
    await this.getSelectStatus()
    await this.getEvents()
    await this.chargeData()
  },
  methods: {
    ...mapActions({
      list: "events/getListMaintenance",
      getSelectStatus: "assets/selectStatus",
      getEvents: "events/getList",
    }),
    toDate(f) {
      if (f) {
        return TimestampToFlatPickr(f);
      }
      return "";
    },
    toDateWithTime(f) {
      if (f) {
        return TimestampToFlatPickrWithTime(f);
      }
      return "";
    },
    chargeData() {
      const client = this.currentClient ? this.currentClient.id : "";
      let filterDateIni = "";
      let filterDateFin = "";
      if (this.date_ini) {
        filterDateIni = FlatPickrToTimestamp(this.date_ini);
      }
      if (this.date_fin) {
        filterDateFin = FlatPickrToTimestamp(this.date_fin);
      }
      const status = this.status ? this.status.id : "";

      if (this.type == null) {
        this.type = { alias: "" };
      }

      const user_id = (this.user) ? this.user.id : ''

      this.list({
        company: this.currentCompany.id,
        client,
        status,
        event_type: this.type.alias,
        page: this.currentPage,
        per_page: this.pageLength,
        date_ini: filterDateIni,
        date_fin: filterDateFin,
        search: this.searchTerm,
        user_id,
      });
    },
    handleSearch() {
      this.currentPage = 1;
      this.chargeData();
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.chargeData();
    },
    handlePageChange() {
      this.currentPage = 1;
      this.chargeData();
    },
    onSortChange(params) {
      this.currentPage = 1;
      this.chargeData();
    },
    viewObject(type) {
      return viewObject(type)
    },
    returnStatus(alias, type) {
      return returnStatus(alias, type)
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
